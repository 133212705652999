import { createContext, useState, useEffect } from 'react';
import { useSites } from '../../hooks/useSites';

const SiteContext = createContext({
    selectedSite: "",
    setSelectedSite: () => {}
});

const SiteProvider = ({ children }) => {

    const sitesAssignedToUser = useSites();
    const [selectedSite, setSelectedSite] = useState();

    useEffect(() => {
        if (sitesAssignedToUser) {
            setSelectedSite(sitesAssignedToUser[0].id);
        }
    }, [sitesAssignedToUser])

    return (
        <SiteContext.Provider value={{ selectedSite, setSelectedSite }}>
            {children}
        </SiteContext.Provider>
    );
};

export { SiteContext, SiteProvider };