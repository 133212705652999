import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Alert } from 'react-bootstrap';
import NoQrScanReason from './NoQrScanReason';
import { QRCodeScanner, VIBRATE_DURATION } from '../QRCodeScanner';
import { useCheckoutJob } from '../../hooks/useJobs';

function ScanQRCodeDialogContent({ job, nextPage, updateJob, closeDialog, valves }) {

    ScanQRCodeDialogContent.propTypes = {
        job: PropTypes.object,
        nextPage: PropTypes.func,
        updateJob: PropTypes.func,
        closeDialog: PropTypes.func,
        valves: PropTypes.object
    }

    const { undoCheckoutJob } = useCheckoutJob();
    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

    const [qrScanResult, setQrScanResult] = useState();
    const [qrScanResultValue, setQrScanResultValue] = useState();
    const [qrScanSuccess, setQrScanSuccess] = useState();
    const [qrScanMessage, setQrScanMessage] = useState();

    const [noQrScanReason, setNoQrScanReason] = useState({});

    useEffect(() => {
        if (qrScanResult && qrScanResult?.text !== qrScanResultValue) {

            navigator.vibrate(VIBRATE_DURATION);

            // New qr code has been received
            console.log("New QR code scanned: " + qrScanResult?.text);
            setQrScanResultValue(qrScanResult?.text);

            // Clear the no scan reason
            setNoQrScanReason({ selectedReason: "", otherReason: "" });

            // Validate valveId
            var validValveId = validateValveIdFromQrCode(qrScanResult?.text, job);
            setQrScanSuccess(validValveId);

            // Set results
            if (validValveId) {
                setQrScanMessage(`Valid QR Code for valve ${job.valveName}`);
                setNextButtonDisabled(false);
            }
            else {
                setQrScanMessage(`Invalid QR Code for valve ${job.valveName}`);
                setNextButtonDisabled(true);
            }
        }
    }, [qrScanResult]);


    const handleQrResult = (result, error) => {
        if (!!result) {
            setQrScanResult(result);
        }
    }

    const guidEquals = function (guid, scannedGuid) {
        if (guid == null)
            return false
        if (scannedGuid == null)
            return false

        return guid.toLowerCase() == scannedGuid.toLowerCase()
    }

    // Qr code format is {siteCode}/Valves/{valveId}
    // Check if the valveId can be extracted and it matches the valveId of the job
    const validateValveIdFromQrCode = (qrCodeValue, job) => {
        //let parts = qrCodeValue.split('/');
        //let valveId = parts[2];

        return guidEquals(job.valveGuid, qrCodeValue)
    }

    // A reason for not scanning is selected
    const handleNoScanReasonCallback = (reason) => {

        // Reason has been selected so reset the qr scan results
        setQrScanResult(undefined);
        setQrScanSuccess(undefined);
        setQrScanMessage(undefined);

        // Set the no scan reason in the job
        job.noQrScanReason = reason.selectedReason === "Other" ? reason.otherReason : reason.selectedReason;

        setNoQrScanReason(reason);

        // Disable next button if no reason is selected
        let disableNextButton = reason.selectedReason === "" || (reason.selectedReason === "Other" && reason.otherReason === "");
        setNextButtonDisabled(disableNextButton);
    }

    const undoCheckoutJobAndClose = async () => {

        job = await undoCheckoutJob(job)

        if (job) {
            updateJob(job);
        }

        closeDialog();

        // add error handling for task and undo checkout
    }

    // If a Qr code has been scanned, show alert for if it was valid or not
    let qrResult = qrScanSuccess == null ? "" : <Alert variant={qrScanSuccess ? "success" : "danger"} className="mt-2">{qrScanMessage}</Alert>

    return (
        <>
            <Modal.Body style={{ minHeight: "300px" }}>
                <QRCodeScanner onScan={handleQrResult} />
                {qrResult}
                <NoQrScanReason reason={noQrScanReason} handleCallback={handleNoScanReasonCallback} />
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <Button variant="warning" style={{ color: 'white' }} onClick={undoCheckoutJobAndClose}>Undo Checkout</Button>
                <Button variant="primary" onClick={nextPage} disabled={nextButtonDisabled}>Next</Button>
            </Modal.Footer>
        </>
    );
}

export default ScanQRCodeDialogContent;
