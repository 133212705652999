import { useState, useEffect } from 'react';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';

export function useValves(siteId) {

    const [loading, setLoading] = useState();
    const [valves, setValves] = useState([]);

    useEffect(() => {
        if (siteId) {
            getValves(siteId);
        }
    }, [siteId]);

    async function getValves() {

        setLoading(true);

        let url = "api/valves";

        if (siteId) {
            url += `?siteId=${siteId}`;
        }

        await fetchDataAuthenticated(url)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setValves(data);
            })
            .catch(error => {
                console.error(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { valves, loading }
}