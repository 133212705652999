import React, { useState, useEffect, useContext } from 'react';
import { Container, Navbar, Nav, Badge } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import { client as publicClientApplication, signOut } from '../utilities/AuthUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { useIdentifyEnvironment } from '../hooks/useIdentifyEnvironment';
import NotifierContext from './Contexts/NotificationContext';

import logo from '../images/cmn-exxonmobil-logo.gif';
import './NavMenu.css';

import ConnectionStatus from './ConnectionStatus';
import SiteSelector from './SiteSelector';


function NavMenu() {

    const [newJobs, setNewJobs] = useState(0);
    const [newActiveTasks, setNewActiveTasks] = useState(0);

    const account = publicClientApplication.getAllAccounts()[0];
    const environment = useIdentifyEnvironment();
    const location = useLocation();
    const notifier = useContext(NotifierContext);

    useEffect(() => {
        notifier.on("jobUpdate", newJob);

        return function () {
            notifier.off("jobUpdate", newJob);
        }

    }, [newJobs]);

    useEffect(() => {
        if (newJobs > 0 && location.pathname === "/jobs")
            setNewJobs(0);

        if (newActiveTasks > 0 && location.pathname === "/active-tasks")
            setNewActiveTasks(0);

    }, [location.pathname]);

    const newJob = (eventData) => {
        if (location.pathname !== "/") {
            var newCount = newJobs + 1;
            setNewJobs(newCount);
        }
    }

    const getActivePath = () => {
        if (location.pathname === "/")
            return "/jobs";

        return location.pathname;
    }

    return (
        <header>
            <Navbar expand="lg" bg="white" variant="light" className="border-bottom box-shadow mb-3">
                <Container fluid="xl">
                    <Navbar.Brand as={Link} to="/" className="me-3">
                        <img src={logo}
                            alt="ExxonMobil"
                            width="150"
                            className="d-inline-block align-middle me-3" />
                        <strong>OMDGo! {environment && environment !== "Production" && `(${environment})`}</strong>
                    </Navbar.Brand>

                    <Navbar.Toggle className="ms-auto" />

                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav.Link as={NavLink} to="/jobs">
                            <Navbar.Text className="text-dark me-2">Jobs</Navbar.Text>
                            {newJobs > 0 && <Badge pill bg="danger">{newJobs}</Badge>}
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/active-tasks">
                            <Navbar.Text className="text-dark me-2">Active Tasks</Navbar.Text>
                            {newActiveTasks > 0 && <Badge pill bg="danger">{newActiveTasks}</Badge>}
                        </Nav.Link>
                    </Navbar.Collapse>

                    <div className="d-flex justify-content-end align-items-center">
                        <Navbar.Collapse>
                            <Nav.Item style={{ padding: '0.5rem 1rem' }}>
                                <SiteSelector />
                            </Nav.Item>
                            <Nav.Link as={Link}>
                                <FontAwesomeIcon icon={faUser} className="me-2" />
                                <Navbar.Text className="text-dark">{account && account.name}</Navbar.Text>
                            </Nav.Link>
                            <Navbar.Text>
                                <Nav.Link onClick={signOut}>Sign Out</Nav.Link>
                            </Navbar.Text>
                        </Navbar.Collapse>
                    </div>
                    <ConnectionStatus className="ms-2" />

                </Container>
            </Navbar>
        </header>
    );
}

export default NavMenu;