import React from 'react';
import ActiveTasksTable from './ActiveTasksTable';
import { Container } from 'react-bootstrap';

function ActiveTasks() {

    return (
        <Container>
            <ActiveTasksTable />
        </Container>
    );
}

export default ActiveTasks;
