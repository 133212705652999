import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

function ValveIdInput({ valves, loadingValves, handleCallback }) {

    ValveIdInput.propTypes = {
        valves: PropTypes.array,
        handleCallback: PropTypes.func
    }

    // Handle change of the 'valveId' selection
    const handleValveIdChange = (selectedValves) => {

        if (selectedValves.length > 0) {
            let valveId = selectedValves[0].tag;
            handleCallback(valveId);
        } else {
            handleCallback("");
        }
    }

    // Handle change of typing in the text box
    const handleTypedValveChange = (text) => {
        handleCallback(text);
    }

    if (loadingValves) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return (
            <Typeahead
                id="valveIdAutoComplete"
                labelKey="tag"
                onChange={handleValveIdChange}
                onInputChange={handleTypedValveChange}
                options={valves}
                placeholder="Enter valve ID"
            />
    );
}

export default ValveIdInput;