import { useState, useEffect } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';

export function useSites() {

    const { setAlert } = useAlert();

    const [sitesAssignedToUser, setSitesAssignedToUser] = useState();

    useEffect(() => {
        getUserSites();
    }, []);

    async function getUserSites() {

        await fetchDataAuthenticated(`api/user/sites`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setSitesAssignedToUser(data);
            })
            .catch(error => {
                setAlert("danger", "Error", "Failed to get sites for user", error.message);
            })
    }

    return sitesAssignedToUser;
}