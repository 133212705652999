import React, { useContext} from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from './DataTable/datatable.component';
import { useGetTasks } from '../hooks/useTasks';
import { SiteContext } from './Contexts/SiteContext';

function ActiveTasksTable() {

    const { selectedSite } = useContext(SiteContext);
    const { tasks, loading } = useGetTasks(selectedSite);

    var initialState = undefined;

    var columns = [
        {
            Header: 'Task',
            accessor: 'task',
            filter: 'fuzzyText'
        },
        {
            Header: 'Order No.',
            accessor: 'orderNumber',
            filter: 'fuzzyText'
        },
        {
            Header: 'Type',
            accessor: 'taskType',
            filter: 'fuzzyText'
        },
        {
            Header: 'Material',
            accessor: 'material',
            filter: 'fuzzyText'
        },
        {
            Header: 'Status',
            accessor: 'status',
            filter: 'fuzzyText'
        },
        {
            Header: 'Source',
            accessor: 'sources',
            filter: 'fuzzyText'
        },
        {
            Header: 'Dest.',
            accessor: 'destinations',
            filter: 'fuzzyText'
        },
        {
            Header: 'Flow',
            accessor: 'targetFlow',
            filter: 'fuzzyText'
        },
        {
            Header: 'Vol.',
            accessor: 'taskVolume',
            filter: 'fuzzyText'
        }
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    if (tasks != null && tasks.length > 0) {
        return (
            <DataTable tableColumns={columns} tableData={tasks} initialState={initialState} />
        );
    } else {
        return (<h5>No jobs available</h5>);
    }
}

export default ActiveTasksTable;