import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { SiteContext } from './Contexts/SiteContext';
import { useSites } from '../hooks/useSites';

function SiteSelector() {

    const sitesAssignedToUser = useSites();
    const { selectedSite, setSelectedSite } = useContext(SiteContext);

    return (
        <>
            {sitesAssignedToUser && sitesAssignedToUser.length > 1 &&
                <Form.Select value={selectedSite && selectedSite} size="sm" name="site" onChange={e => setSelectedSite(e.target.value)}>
                    {
                        sitesAssignedToUser.map((site, i) => {
                            return <option key={i + 1} value={site.id}>{site.siteCode}</option>
                        })
                    }
                </Form.Select>
            }
            {sitesAssignedToUser && sitesAssignedToUser.length === 1 &&
                <span className="font-weight-bold">{sitesAssignedToUser[0].siteCode}</span>
            }
        </>
    );
}

export default SiteSelector;