import React, { useState, useEffect } from 'react';
import './ConnectionStatus.css';
import NotifierContext from './Contexts/NotificationContext';

// SignOutButton Component returns a button that invokes a redirect logout when clicked
export default function ConnectionStatus({ className = "" }) {

    const [currentStatus, setCurrentStatus] = useState("Unknown");

    var notifier = React.useContext(NotifierContext);

    useEffect(() => {

        notifier.on("omdGoStatusChanged", onStatusChange);

        return function () {
            notifier.off("omdGoStatusChanged", onStatusChange);
        }

    }, [currentStatus]);

    const onStatusChange = (eventData) => {
        var newStatus = eventData.detail.data;

        if (newStatus.isGood === true) {
            setCurrentStatus("Connected");
        }
        else {
            setCurrentStatus("Bad");
        }
    }

    function statusToColor(status) {

        switch (status) {
            case "Connected":
                return "connected";
            case "Bad":
                return "bad";
            default:
                return "unknown"
        }
    }

    return <div title={currentStatus} className={"dot " + statusToColor(currentStatus) + " " + className} />
};