import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Alert, Spinner } from 'react-bootstrap';
import TaskTable from '../TaskTable';
import { useMsal } from "@azure/msal-react";
import { useGetTask } from '../../hooks/useTasks';
import { useCheckoutJob } from '../../hooks/useJobs';
import { SiteContext } from '../Contexts/SiteContext';

function CheckedOutJobDialogContent({ job, nextPage, updateJob, closeDialog }) {

    CheckedOutJobDialogContent.propTypes = {
        job: PropTypes.object,
        nextPage: PropTypes.func,
        updateJob: PropTypes.func,
        closeDialog: PropTypes.func
    }

    const { selectedSite } = useContext(SiteContext);
    const { task, loading, error } = useGetTask(selectedSite, job.taskName);
    const { undoCheckoutJob } = useCheckoutJob();

    const { accounts } = useMsal();
    const username = accounts[0].username;

    const undoCheckoutJobAndClose = async () => {

        job = await undoCheckoutJob(job)
        updateJob(job);
        closeDialog();

        // add error handling for task and undo checkout
    }

    // wait for task to load
    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return (
        <>
            <Modal.Body style={{ minHeight: '300px' }}>
                <div className="my-3">
                    <p>Required Action: <b>{job.action}</b></p>
                    <div className="mb-4">
                        <TaskTable task={task} />
                    </div>
                </div>
                <p>Checked out by {job.assignedUser}</p>
                {username !== job.assignedUser &&
                    <Alert variant="danger" className="mt-2">You cannot work on a job checked out to another user</Alert>
                }
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <Button variant="warning" style={{ color: 'white' }} onClick={undoCheckoutJobAndClose}>Undo Checkout</Button>
                <Button variant="primary" onClick={nextPage} disabled={username !== job.assignedUser}>Next</Button>
            </Modal.Footer>
        </>
    );
}

export default CheckedOutJobDialogContent;