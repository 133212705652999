import React from 'react';
import PropTypes from 'prop-types';

function TaskTable({ task }) {

    TaskTable.propTypes = {
        task: PropTypes.object
    }

    if (!task) {
        return <p><em>Loading task information..</em></p>
    }

    if (task.error) {
        return <p><em>{task.error} So no additional task information can be displayed at this time.</em></p>
    }

    return (
        <table className="table" aria-labelledby="tabelLabel">
            <thead>
                <tr>
                    <th>{task.task}</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Order</th>
                    <td>{task.orderNumber}</td>

                    <th>Source</th>
                    <td>{task.sources}</td>

                    <th>Destination</th>
                    <td>{task.destinations}</td>
                </tr>
                <tr>
                    <th>Type</th>
                    <td>{task.taskType}</td>
                    <th>Material</th>
                    <td>{task.material}</td>
                    <th>Status</th>
                    <td>{task.status}</td>
                </tr>
                <tr>
                    <th>Flow Target</th>
                    <td>{task.targetFlow}</td>

                    <th>Volume Target</th>
                    <td>{task.targetVolume}</td>

                    <th></th>
                    <td></td>
                </tr>
            </tbody>
        </table>
    );
}

export default TaskTable;