import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { client as publicClientApplication } from './utilities/AuthUtils';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

publicClientApplication.initialize();

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={publicClientApplication}>
            <BrowserRouter basename={baseUrl}>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                    <App />
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                    <p>Unauthorized</p>
                </UnauthenticatedTemplate>
            </BrowserRouter>
        </ MsalProvider>
    </React.StrictMode>,
    rootElement);

// registerServiceWorker();
