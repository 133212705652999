import React, { useState, useEffect } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';


function Notifier() {

    const [connection, setConnection] = useState(null);

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('/notifications')
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {

        const handleConnection = async () => {
            if (connection) {
                connection.start({ withCredentials: false })
                    .then(result => {
                        console.log('NotifierService Connected');

                        connection.on('JobUpdated', (job) => {
                            trigger("jobUpdate", { data: job })
                        });
                        connection.on('NewJob', (job) => {
                            trigger("newJob", { data: job })
                        });
                        connection.on('NewActiveTask', (job) => {
                            trigger("newActiveTask", { data: job })
                        });
                        connection.on('OMDGoStatusChanged', (status) => {
                            trigger("omdGoStatusChanged", { data: status })
                        });
                    })
                    .catch(e => console.log('NotifierService connection failed: ', e));
            }
        }

        handleConnection();
    }, [connection]);

    function on(eventType, listener) {
        document.addEventListener(eventType, listener);
    }

    function off(eventType, listener) {
        document.removeEventListener(eventType, listener);
    }

    function trigger(eventType, data) {
        const event = new CustomEvent(eventType, { detail: data });
        document.dispatchEvent(event);
    }

    return {
        on: on,
        off: off
    }
}

export default Notifier;