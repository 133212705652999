import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Spinner } from 'react-bootstrap';
import TaskTable from '../TaskTable';
import { useGetTask } from '../../hooks/useTasks';
import { useCheckoutJob } from '../../hooks/useJobs';
import { SiteContext } from '../Contexts/SiteContext';

function AvailableJobDialogContent({ job, nextPage, updateJob, closeDialog }) {

    AvailableJobDialogContent.propTypes = {
        job: PropTypes.object,
        nextPage: PropTypes.func,
        updateJob: PropTypes.func,
        closeDialog: PropTypes.func
    }

    const { selectedSite } = useContext(SiteContext);
    const { task, loading, error } = useGetTask(selectedSite, job.taskName);
    const { checkoutJob } = useCheckoutJob();

    const checkoutJobAndClose = async () => {

        job = await checkoutJob(job)
        updateJob(job);
        closeDialog();

        // add error handling for task and checkout
    }

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return (
        <>
            <Modal.Body style={{ minHeight: '300px' }}>
                <div className="my-3">
                    <p>Required Action: <b>{job.action}</b></p>
                    <div className="mb-4">
                        <TaskTable task={task} />
                    </div>
                    <p>To start job, checkout the job.</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <Button variant="warning" style={{ color: 'white' }} onClick={checkoutJobAndClose}>Checkout</Button>
            </Modal.Footer>
        </>
    );
}

export default AvailableJobDialogContent;
