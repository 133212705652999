import { useState, useEffect } from 'react';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';
import useAlert from '../components/Alerts/useAlert';

export function useGetTasks(siteId) {

    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState();
    const { setAlert } = useAlert();

    useEffect(() => {
        if (siteId) {
            getTasks(siteId);
        }
    }, [siteId]);

    async function getTasks(siteId) {

        setLoading(true);

        let url = "api/activeTasks";

        if (siteId) {
            url += `?siteId=${siteId}`;
        }

        await fetchDataAuthenticated(url)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setTasks(data);
            })
            .catch(error => {
                console.error();
                setAlert("danger", "Error", "Failed to get tasks", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { tasks, loading }
}

export function useGetTask(siteId, taskName) {

    const [task, setTask] = useState([]);
    const [loading, setLoading] = useState(true);
    const { setAlert } = useAlert();

    useEffect(() => {
        getTask(siteId);
    }, [siteId]);

    async function getTask(siteId) {

        await fetchDataAuthenticated(`api/activeTasks/${siteId}/${taskName}`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setTask(data);
            })
            .catch(error => {
                console.error();
                setAlert("danger", "Error", "Failed to get tasks", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { task, loading }
}