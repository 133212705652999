import React from 'react';
import { QrReader } from 'react-qr-reader';

export const VIBRATE_DURATION = 100;

export function QRCodeScanner({ onScan }) {

    return <QrReader
        constraints={{ facingMode: 'environment' }}
        onResult={onScan}
        style={{ width: "100%" }}
        videoContainerStyle={{ paddingTop: "75%" }}
        ViewFinder={QRCodeViewFinderOverlay} />;
}

function QRCodeViewFinderOverlay() {
    return (
        <svg viewBox="0 0 100 100" className="scan-overlay">
            <path fill="none" d="M13,0 L0,0 L0,13" stroke="rgba(255, 255, 255, 0.5)" strokeWidth="5" />
            <path fill="none" d="M0,87 L0,100 L13,100" stroke="rgba(255, 255, 255, 0.5)" strokeWidth="5" />
            <path fill="none" d="M87,100 L100,100 L100,87" stroke="rgba(255, 255, 255, 0.5)" strokeWidth="5" />
            <path fill="none" d="M100,13 L100,0 87,0" stroke="rgba(255, 255, 255, 0.5)" strokeWidth="5" />
        </svg>
    );
}

export default QRCodeScanner;