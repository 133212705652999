import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';

function NoQrScanReason({ reason, handleCallback }) {

    NoQrScanReason.propTypes = {
        reason: PropTypes.object,
        handleCallback: PropTypes.func
    }

    // Handle change of the reason dropdown
    const handleReasonChange = (event) => {
        let selectedReason = event.target.value;
        handleCallback({ selectedReason: selectedReason, otherReason: "" });
    };

    // Handle change of the 'other reason' textbox
    const handleOtherReasonChange = (event) => {
        let otherReason = event.target.value;
        handleCallback({ selectedReason: "Other", otherReason: otherReason });
    };

    return (
        <div>
            <Form.Group className="my-2">
                <Form.Select name="noScanReason" value={reason && reason.selectedReason} onChange={handleReasonChange}>
                    <option key="0" value="">Cannot scan qr code? Select reason...</option>
                    <option key="1" value="QrCodeMissing">QR code missing</option>
                    <option key="2" value="QrCodeDamaged">QR code damaged</option>
                    <option key="3" value="DeviceProblem">Device problem</option>
                    <option key="4" value="Other">Other</option>
                </Form.Select>
            </Form.Group>
            {
                reason.selectedReason === "Other" &&
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column>Please specify: </Form.Label>
                    <Col sm={8}>
                        <Form.Control value={reason.otherReason} onChange={handleOtherReasonChange} />
                    </Col>
                </Form.Group>
            }
        </div>
    );
}

export default NoQrScanReason;