export function useIdentifyEnvironment() {

    const url = window.location.href;

    if (url.includes("dev") || url.includes("localhost")) {
        return "Dev";
    } else if (url.includes("acc")) {
        return "Acc";
    } else {
        return "Prd";
    }
}